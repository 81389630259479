<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">content block with images and captions</div>
        <div class="module-preview--contents">
            <div class="info-wrapper self-center">
                <div class="font-600 text-lg">{{ getValue('title') }}</div>
                <div class="font-500 text-base mt-1">{{ getValue('subTitle') }}</div>
            </div>
            <div class="image-grid">
                <div class="image">
                    <div class="image-wrapper">
                        <img :src="getValue('imageOne')" alt="">
                    </div>
                    <div class="font-600 text-base mt-3">{{ getValue('imageOneTitle') }}</div>
                    <p class="mt-2 text-sm" v-if="getValue('imageOneCaption')">{{ truncate(getValue('imageOneCaption'), {length: 60}) }}</p>
                </div>
                <div class="image">
                    <div class="image-wrapper">
                        <img :src="getValue('imageTwo')" alt="">
                    </div>
                    <div class="font-600 text-base mt-3">{{ getValue('imageTwoTitle') }}</div>
                    <p class="mt-2 text-sm" v-if="getValue('imageTwoCaption')">{{ truncate(getValue('imageTwoCaption'), {length: 60}) }}</p>
                </div>
                <div class="image">
                    <div class="image-wrapper">
                        <img :src="getValue('imageThree')" alt="">
                    </div>
                    <div class="font-600 text-base mt-3">{{ getValue('imageThreeTitle') }}</div>
                    <p class="mt-2 text-sm" v-if="getValue('imageThreeCaption')">{{ truncate(getValue('imageThreeCaption'), {length: 60}) }}</p>
                </div>
                <div class="image">
                    <div class="image-wrapper">
                        <img :src="getValue('imageFour')" alt="">
                    </div>
                    <div class="font-600 text-base mt-3">{{ getValue('imageFourTitle') }}</div>
                    <p class="mt-2 text-sm" v-if="getValue('imageFourCaption')">{{ truncate(getValue('imageFourCaption'), {length: 60}) }}</p>
                </div>                  
            </div>      
        </div>
    </div>
</template>

<script>
import Widget from "@/mixins/Widget";
import {truncate} from 'lodash-es';

export default {
    mixins: [ Widget ],

    methods: {
        truncate,
    },
}
</script>
<style scoped>
    .module-preview--contents{
        grid-template-columns: 1fr;
    }
    .module-preview--contents .images{
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-gap: 30px;
    }
    .module-preview--contents .image-wrapper{
        height: 250px;
        width: 100%;
        overflow: hidden;
    } 
    .module-preview--contents .image-wrapper img{
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
    } 
    .image-grid{
        display:grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
    }
</style>